import React from 'react';
import FantasyPlayerStatsApp from "./FantasyPlayerStatsApp";
import './App.css';

function App() {
    return (
        <div className="App">
            <FantasyPlayerStatsApp />
        </div>
    );
}

export default App;
