import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slider,
    TextField,
    Typography,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup
} from '@mui/material';

import Grid from '@mui/material/Grid2';
import Autocomplete from '@mui/material/Autocomplete';

const FilterDialog = ({ open, onClose, initialFilters, playerData, onApply }) => {
    const calculateMinMax = (attribute, isFloat = false) => {
        const values = playerData.map((player) => (isFloat ? parseFloat(player[attribute]) : parseInt(player[attribute], 10)));
        return [Math.min(...values), Math.max(...values)];
    };

    const filterLabels = {
        fptRange: "Fantasy Points Range",
        minutesRange: "Minutes Played Range",
        pointsRange: "Points Range",
        reboundsRange: "Rebounds Range",
        assistsRange: "Assists Range",
        stealsRange: "Steals Range",
        blocksRange: "Blocks Range",
        turnoversRange: "Turnovers Range",
        personalFoulsRange: "Personal Fouls Range",
        foulsDrawnRange: "Fouls Drawn Range",
        fieldGoalsMadeRange: "Field Goals Made Range",
        fieldGoalsAttemptedRange: "Field Goals Attempted Range",
        threePointersMadeRange: "Three-Pointers Made Range",
        threePointersAttemptedRange: "Three-Pointers Attempted Range",
        freeThrowsMadeRange: "Free Throws Made Range",
        freeThrowsAttemptedRange: "Free Throws Attempted Range",
        plusMinusRange: "Plus/Minus Range"
    };


    const ranges = {
        fptRange: calculateMinMax('fpt', true),
        minutesRange: calculateMinMax('min', true),
        pointsRange: calculateMinMax('points'),
        reboundsRange: calculateMinMax('rebounds'),
        assistsRange: calculateMinMax('assists'),
        stealsRange: calculateMinMax('steals'),
        blocksRange: calculateMinMax('blocks'),
        turnoversRange: calculateMinMax('turnovers'),
        personalFoulsRange: calculateMinMax('personalFouls'),
        foulsDrawnRange: calculateMinMax('foulsDrawn'),
        fieldGoalsMadeRange: calculateMinMax('fieldGoalsMade'),
        fieldGoalsAttemptedRange: calculateMinMax('fieldGoalsAttempted'),
        threePointersMadeRange: calculateMinMax('threePointersMade'),
        threePointersAttemptedRange: calculateMinMax('threePointersAttempted'),
        freeThrowsMadeRange: calculateMinMax('freeThrowsMade'),
        freeThrowsAttemptedRange: calculateMinMax('freeThrowsAttempted'),
        plusMinusRange: calculateMinMax('plusMinus')
    };

    const uniqueNames = [...new Set(playerData.map((player) => player.name))];
    const uniquePositions = [...new Set(playerData.map((player) => player.position))];
    const uniqueTeams = [...new Set(playerData.map((player) => player.team))];

    const [filters, setFilters] = useState({
        ...ranges,
        name: [],
        position: [],
        team: []
    });

    const handleFilterChange = (name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    };

    const handleCheckboxChange = (name, value) => {
        setFilters((prevFilters) => {
            const currentValues = prevFilters[name];
            return {
                ...prevFilters,
                [name]: currentValues.includes(value)
                    ? currentValues.filter((v) => v !== value)
                    : [...currentValues, value]
            };
        });
    };

    const handleResetFilters = () => {
        setFilters({
            ...ranges,
            name: [],
            position: [],
            team: []
        });
    };

    const handleApplyFilters = () => {
        const filtered = playerData.filter(
            (player) =>
                (filters.name.length === 0 || filters.name.includes(player.name)) &&
                (filters.position.length === 0 || filters.position.includes(player.position)) &&
                (filters.team.length === 0 || filters.team.includes(player.team)) &&
                parseFloat(player.fpt) >= filters.fptRange[0] &&
                parseFloat(player.fpt) <= filters.fptRange[1] &&
                parseFloat(player.min) >= filters.minutesRange[0] &&
                parseFloat(player.min) <= filters.minutesRange[1] &&
                parseInt(player.points) >= filters.pointsRange[0] &&
                parseInt(player.points) <= filters.pointsRange[1] &&
                parseInt(player.rebounds) >= filters.reboundsRange[0] &&
                parseInt(player.rebounds) <= filters.reboundsRange[1] &&
                parseInt(player.assists) >= filters.assistsRange[0] &&
                parseInt(player.assists) <= filters.assistsRange[1] &&
                parseInt(player.steals) >= filters.stealsRange[0] &&
                parseInt(player.steals) <= filters.stealsRange[1] &&
                parseInt(player.blocks) >= filters.blocksRange[0] &&
                parseInt(player.blocks) <= filters.blocksRange[1] &&
                parseInt(player.turnovers) >= filters.turnoversRange[0] &&
                parseInt(player.turnovers) <= filters.turnoversRange[1] &&
                parseInt(player.personalFouls) >= filters.personalFoulsRange[0] &&
                parseInt(player.personalFouls) <= filters.personalFoulsRange[1] &&
                parseInt(player.foulsDrawn) >= filters.foulsDrawnRange[0] &&
                parseInt(player.foulsDrawn) <= filters.foulsDrawnRange[1] &&
                parseInt(player.fieldGoalsMade) >= filters.fieldGoalsMadeRange[0] &&
                parseInt(player.fieldGoalsMade) <= filters.fieldGoalsMadeRange[1] &&
                parseInt(player.fieldGoalsAttempted) >= filters.fieldGoalsAttemptedRange[0] &&
                parseInt(player.fieldGoalsAttempted) <= filters.fieldGoalsAttemptedRange[1] &&
                parseInt(player.threePointersMade) >= filters.threePointersMadeRange[0] &&
                parseInt(player.threePointersMade) <= filters.threePointersMadeRange[1] &&
                parseInt(player.threePointersAttempted) >= filters.threePointersAttemptedRange[0] &&
                parseInt(player.threePointersAttempted) <= filters.threePointersAttemptedRange[1] &&
                parseInt(player.freeThrowsMade) >= filters.freeThrowsMadeRange[0] &&
                parseInt(player.freeThrowsMade) <= filters.freeThrowsMadeRange[1] &&
                parseInt(player.freeThrowsAttempted) >= filters.freeThrowsAttemptedRange[0] &&
                parseInt(player.freeThrowsAttempted) <= filters.freeThrowsAttemptedRange[1] &&
                parseInt(player.plusMinus) >= filters.plusMinusRange[0] &&
                parseInt(player.plusMinus) <= filters.plusMinusRange[1]
        );
        onApply(filtered);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Filter Players</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} rowSpacing={2} columnSpacing={4}>
                    <Grid item size={12}>
                        <Autocomplete
                            multiple
                            options={uniqueNames}
                            value={filters.name}
                            onChange={(event, newValue) => handleFilterChange('name', newValue)}
                            renderInput={(params) => <TextField {...params} label="Name" />}
                            fullWidth
                        />
                    </Grid>

                    <Grid item size={12}>
                        <Typography gutterBottom>Position</Typography>
                        <FormControl component="fieldset">
                            <FormGroup row>
                                {uniquePositions.map((position) => (
                                    <FormControlLabel
                                        key={position}
                                        control={
                                            <Checkbox
                                                checked={filters.position.includes(position)}
                                                onChange={() => handleCheckboxChange('position', position)}
                                            />
                                        }
                                        label={position}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Grid>

                    <Grid item size={12}>
                        <Typography gutterBottom>Team</Typography>
                        <FormControl component="fieldset">
                            <FormGroup row>
                                {uniqueTeams.map((team) => (
                                    <FormControlLabel
                                        key={team}
                                        control={
                                            <Checkbox
                                                checked={filters.team.includes(team)}
                                                onChange={() => handleCheckboxChange('team', team)}
                                            />
                                        }
                                        label={team}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Grid>

                    {Object.keys(ranges).map((key) => (
                        <Grid item size={6} key={key}>
                            <Typography gutterBottom>{filterLabels[key]}</Typography>
                            <Slider
                                value={filters[key] || ranges[key]}
                                onChange={(e, newValue) => handleFilterChange(key, newValue)}
                                valueLabelDisplay="auto"
                                min={ranges[key][0]}
                                max={ranges[key][1]}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleResetFilters} color="default">
                    Reset
                </Button>
                <Button onClick={handleApplyFilters} color="primary">
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FilterDialog;
