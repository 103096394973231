import React, {useState, useEffect} from 'react';
import playerData from './data/total.json';
import FilterDialog from './FilterDialog';
import {
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    Pagination,
    Container,
    Select,
    MenuItem,
    ThemeProvider,
    createTheme,
} from '@mui/material';

import Grid from '@mui/material/Grid2';

const dataContext = require.context('./data', false, /\.json$/);

const themeFel = createTheme({
    typography: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 13,
    },
    palette: {
        primary: {
            main: '#219ebc',
            light: '#8ecae6',
            dark: '#023047',
            contrastText: '#FFF',
        },
        el: {
            main: '#F55600',
            light: '#FF8847',
            dark: '#CC4700',
            grey: '#e6e8e6',
            contrastText: '#e6e8e6',
            contrastTextDark: '#AAB1AA',
        }
    }
});

const computeDerivedStats = (data) => {
    return data.map((player) => {
        const fpt = parseFloat(player.fpt);
        const min = parseFloat(player.min);
        const cr = parseFloat(player.cr);

        const fptPerMin =
            min && min !== 0 && !isNaN(fpt) && !isNaN(min)
                ? fpt / min
                : null;

        const fptPerCredit =
            cr && cr !== 0 && !isNaN(fpt) && !isNaN(cr)
                ? fpt / cr
                : null;

        return {
            ...player,
            fptPerMin,
            fptPerCredit,
        };
    });
};

const FantasyPlayerStatsApp = () => {
    const [filterDialogOpen, setFilterDialogOpen] = useState(false);
    const [filteredData, setFilteredData] = useState(computeDerivedStats(playerData));
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState("desc");
    const [showBasicStats, setShowBasicStats] = useState(true);
    const [showShootingStats, setShowShootingStats] = useState(false);
    const [showAdvancedStats, setShowAdvancedStats] = useState(false);
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;
    const [selectedRound, setSelectedRound] = useState('total');
    const [availableRounds, setAvailableRounds] = useState([]);

    useEffect(() => {
        const rounds = dataContext.keys().map((key) => {
            return key.replace('./', '').replace('.json', '');
        });
        console.log('rounds: ', rounds);
        setAvailableRounds(rounds);
    }, []);

    useEffect(() => {
        const loadRoundData = async () => {
            try {
                console.log('selectedRound', selectedRound);
                console.log('availableRounds', availableRounds);

                // Ensure availableRounds is populated before attempting to load data
                if (availableRounds.length > 0 && availableRounds.includes(selectedRound)) {
                    const importedData = await dataContext(`./${selectedRound}.json`);
                    console.log('importedData', importedData);

                    if (importedData) {
                        setFilteredData(computeDerivedStats(importedData));
                    } else {
                        console.error('Imported data is empty:', importedData);
                    }
                } else if (availableRounds.length > 0) {
                    console.error(`Round ${selectedRound} is not available in availableRounds`);
                }
            } catch (error) {
                console.error('Error loading round data:', error);
            }
        };

        if (availableRounds.length > 0) {
            loadRoundData();
        }
    }, [selectedRound, availableRounds]);


    const numericColumns = [
        'cr', 'plus', 'fpt', 'min', 'points', 'rebounds', 'assists', 'steals', 'blocks',
        'turnovers', 'personalFouls', 'foulsDrawn', 'fieldGoalsMade',
        'fieldGoalsAttempted', 'threePointersMade', 'threePointersAttempted',
        'freeThrowsMade', 'freeThrowsAttempted', 'plusMinus', 'fptPerMin', 'fptPerCredit'
    ];

    const columnHeaders = [
        {label: 'Name', column: 'name'},
        {label: 'Position', column: 'position'},
        {label: 'Team', column: 'team'},
        {label: 'Price', column: 'cr'},
        {label: 'Change', column: 'plus'},
        {label: 'PIR', column: 'fpt'},
        {label: 'MIN', column: 'min'},
        {label: 'PTS', column: 'points'},
        ...(showBasicStats ? [
            {label: 'REB', column: 'rebounds'},
            {label: 'AST', column: 'assists'},
            {label: 'STL', column: 'steals'},
            {label: 'BLK', column: 'blocks'},
            {label: 'TO', column: 'turnovers'},
            {label: 'Fouls', column: 'personalFouls'},
            {label: 'FD', column: 'foulsDrawn'},
            {label: '+/-', column: 'plusMinus'},
        ] : []),
        ...(showShootingStats ? [
            {label: 'FGM', column: 'fieldGoalsMade'},
            {label: 'FGA', column: 'fieldGoalsAttempted'},
            {label: '3PM', column: 'threePointersMade'},
            {label: '3PA', column: 'threePointersAttempted'},
            {label: 'FTM', column: 'freeThrowsMade'},
            {label: 'FTA', column: 'freeThrowsAttempted'}
        ] : []),
        ...(showAdvancedStats ? [
            {label: 'FPT/MIN', column: 'fptPerMin'},
            {label: 'PIR/CRD', column: 'fptPerCredit'}
        ] : []),

    ];

    const openFilterDialog = () => setFilterDialogOpen(true);
    const closeFilterDialog = () => setFilterDialogOpen(false);
    const toggleBasicStats = () => setShowBasicStats(!showBasicStats);
    const toggleShootingStats = () => setShowShootingStats(!showShootingStats);
    const toggleAdvancedStats = () => setShowAdvancedStats(!showAdvancedStats);
    const handleRoundChange = (event) => {
        setSelectedRound(event.target.value);
    };

    const normalizeMinusSign = (str) => {
        return String(str).replace(/[\u2012\u2013\u2014\u2015\u2212]/g, '-');
    };

    const handleApplyFilters = (filteredPlayers) => {
        const updatedData = computeDerivedStats(filteredPlayers);
        setFilteredData(updatedData);
        closeFilterDialog();
    };


    const handleSort = (column) => {
        const isAsc = sortColumn === column && sortDirection === "asc";
        setSortDirection(isAsc ? "desc" : "asc");
        setSortColumn(column);

        const sortedData = [...filteredData].sort((a, b) => {
            let aValue = a[column];
            let bValue = b[column];

            if (numericColumns.includes(column)) {
                aValue = normalizeMinusSign(aValue);
                bValue = normalizeMinusSign(bValue);

                const extractNumber = (str) => {
                    const match = String(str).match(/[-+]?\d*\.?\d+/);
                    return match ? parseFloat(match[0]) : 0;
                };

                aValue = extractNumber(aValue);
                bValue = extractNumber(bValue);
            }

            if (aValue < bValue) return isAsc ? -1 : 1;
            if (aValue > bValue) return isAsc ? 1 : -1;
            return 0;
        });

        setFilteredData(sortedData);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <div className="App">
            <ThemeProvider theme={themeFel}>
                <Container maxWidth={false} style={{padding: '20px'}}>
                    <Typography variant="h4" gutterBottom>
                        Fantasy Player Stats
                    </Typography>
                    <Grid container spacing={0.5}>
                        <Select value={selectedRound} onChange={handleRoundChange}>
                            {availableRounds.map((round) => (
                                <MenuItem key={round} value={round}>
                                    {round === 'total' ? 'Total' : round.replace('round', 'Round ')}
                                </MenuItem>
                            ))}
                        </Select>
                        <Button variant="contained" color="primary" onClick={openFilterDialog}>
                            Open Filters
                        </Button>
                        <Button
                            variant="contained"
                            color="custom"
                            onClick={toggleBasicStats}
                            style={{
                                backgroundColor: showBasicStats ? themeFel.palette.el.grey : themeFel.palette.el.main,
                                color: showBasicStats ? themeFel.palette.el.contrastTextDark : themeFel.palette.el.contrastText

                            }}
                        >
                            {showBasicStats ? 'Hide Basic Stats' : 'Show Basic Stats'}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={toggleShootingStats}
                            style={{
                                backgroundColor: showShootingStats ? themeFel.palette.el.grey : themeFel.palette.el.main,
                                color: showShootingStats ? themeFel.palette.el.contrastTextDark : themeFel.palette.el.contrastText
                            }}
                        >
                            {showShootingStats ? 'Hide Shooting Stats' : 'Show Shooting Stats'}
                        </Button>
                        <Button
                            variant="contained"
                            color="custom"
                            onClick={toggleAdvancedStats}
                            style={{
                                backgroundColor: showAdvancedStats ? themeFel.palette.el.grey : themeFel.palette.el.main,
                                color: showAdvancedStats ? themeFel.palette.el.contrastTextDark : themeFel.palette.el.contrastText
                            }}
                        >
                            {showAdvancedStats ? 'Hide Advanced Stats' : 'Show Advanced Stats'}
                        </Button>
                    </Grid>
                    <FilterDialog
                        open={filterDialogOpen}
                        onClose={closeFilterDialog}
                        initialFilters={{}}
                        playerData={playerData}
                        onApply={handleApplyFilters}
                    />

                    <TableContainer component={Paper} style={{marginTop: 20}}>
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {columnHeaders.map(({label, column}) => (
                                        <TableCell
                                            key={column}
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            <TableSortLabel
                                                active={sortColumn === column}
                                                direction={sortColumn === column ? sortDirection : 'asc'}
                                                onClick={() => handleSort(column)}
                                            >
                                                {label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((player, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                <a href={player.link} target="_blank" rel="noopener noreferrer">
                                                    {player.name}
                                                </a>
                                            </TableCell>
                                            <TableCell>{player.position}</TableCell>
                                            <TableCell>{player.team}</TableCell>
                                            <TableCell>{player.cr}</TableCell>
                                            <TableCell>{player.plus}</TableCell>
                                            <TableCell>{player.fpt}</TableCell>
                                            <TableCell>{player.min}</TableCell>
                                            <TableCell>{player.points}</TableCell>
                                            {showBasicStats && (
                                                <>
                                                    <TableCell>{player.rebounds}</TableCell>
                                                    <TableCell>{player.assists}</TableCell>
                                                    <TableCell>{player.steals}</TableCell>
                                                    <TableCell>{player.blocks}</TableCell>
                                                    <TableCell>{player.turnovers}</TableCell>
                                                    <TableCell>{player.personalFouls}</TableCell>
                                                    <TableCell>{player.foulsDrawn}</TableCell>
                                                    <TableCell>{player.plusMinus}</TableCell>
                                                </>
                                            )}
                                            {showShootingStats && (
                                                <>
                                                    <TableCell>{player.fieldGoalsMade}</TableCell>
                                                    <TableCell>{player.fieldGoalsAttempted}</TableCell>
                                                    <TableCell>{player.threePointersMade}</TableCell>
                                                    <TableCell>{player.threePointersAttempted}</TableCell>
                                                    <TableCell>{player.freeThrowsMade}</TableCell>
                                                    <TableCell>{player.freeThrowsAttempted}</TableCell>
                                                </>
                                            )}
                                            {showAdvancedStats && (
                                                <>
                                                    <TableCell>
                                                        {player.fptPerMin !== null ? player.fptPerMin.toFixed(2) : 'N/A'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {player.fptPerCredit !== null ? player.fptPerCredit.toFixed(2) : 'N/A'}
                                                    </TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Pagination
                        count={Math.ceil(filteredData.length / rowsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        color="primary"
                        style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}
                    />
                </Container>
            </ThemeProvider>
        </div>
    );
};

export default FantasyPlayerStatsApp;
